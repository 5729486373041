
export default function init()
{

    $('.js-icons-choice-widget').each(function()
    {
        let widget = $(this);

        widget.select2({
            dropdownParent: widget.closest('.modal'),
            escapeMarkup: data => data,

            matcher: function(search, icon)
            {
                if(search.term === undefined || search.term.length === 0)
                {
                    return icon;
                }

                search = search.term.toLowerCase();

                if(icon.id.indexOf(search) !== -1 || icon.text.indexOf(search) !== -1)
                {
                    return icon;
                }

                return null;
            }
        });
    });

};
