export default function collectionInstance() {
	//DELETE ROW
	var deleteDiv = '<div class="col-xs-1"><button type="button" class="btn btn-danger js-collection-delete-btn"><span class="fa fa-trash"></span></button></div>';

	$(document).on('click', '.js-collection-delete-btn', function () {
		$(this).closest('.js-collection-child').remove();
		$(this).closest('.js-collection-child-exists').remove();
	});

	$('.js-collection-widget.delete .js-collection-child-exists').each(function () {
		if (!$(this).hasClass('has-delete')) {
			$(this).find('.form-group:last-child').last().append(deleteDiv);
			$(this).addClass('has-delete')
		}
	});


	//ADD ROW
	//CollectionType id: $relationshipName
	//'Add'-button id: $relationshipName_add
	$('.js-collection-add-btn').click(function (e) {
		e.preventDefault();

		var holder = $(this).parent('.js-collection-container');
		var list = holder.find($('.js-collection-widget [data-prototype]'));

		// grab the prototype template
		var newWidget = list.attr('data-prototype');
		// replace the "__name__" used in the id and name of the prototype
		// with a number that's unique to your categories
		// end name attribute looks like name="mainForm[subForm][2]"
		newWidget = newWidget.replace(/__name__/g, list.children().length);

		// create a new list element and add it to the list
		var newLi = $('<div class="js-collection-child"></div>').html(newWidget);

		newLi.appendTo(list);
		loadSelect2();
		checkDeleteButtons();

        holder.trigger('item:added')
	});

	function checkDeleteButtons() {
		$('.js-collection-widget.delete .js-collection-child').each(function () {
			if (!$(this).hasClass('has-delete')) {
				$(this).find('.form-group:last-child').last().append(deleteDiv);
				$(this).addClass('has-delete')
			}
		});
	}

	function loadSelect2() {

		let opened = false;
		$(".js-member-select").each(function (index) {

			if (!searchUrl) {
				return;
			}

			var checkbox = 'js-member-chain-select-' + index;
			if (chainSearching) {
				if (!$(this).parent().find('.form-input-chain').length) {
					$(this).parent().append('<label class="form-input-chain"><input class="include-chain-field ' + checkbox + '" type="checkbox"><span>Keten</span></label>');
				}
			}

			$(this).select2({
				dropdownParent: $(this).parent(),
				minimumInputLength: 2,
				templateResult: function (d) {
					return $(d.text);
				},
				templateSelection: function (d) {
					return $(d.text);
				},
				ajax: {


					url: searchUrl,
					dataType: 'json',
					type: "GET",
					quietMillis: 50,
					data: function (term) {
						return {
							term: term,
							chain: $('.' + checkbox).prop('checked') ? 1 : 0
						};
					},
					processResults: function (data) {
						return {
							results: $.map(data, function (item) {
								return {
									text: '<span>' + item.status + ' ' + item.text + '</span>',
									id: item.id
								}
							})
						};
					}
				}
			});
			if (!$(this).val() && !opened) {
				$(this).select2('open');
				opened = true;
			}
			if (chainSearching) {
				$(this).parent().addClass(' form-input--withChain');
			}

		});
		$("select.select2").each(function () {

			$(this).select2({
				dropdownParent: $(this).parent()
			});
		});
	}
};
