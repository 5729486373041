
export default function init()
{

    $('.js-program-section-container').each(function()
    {
        let container = $(this);

        let programSections = container.data('sections');
        let programsForm = $(container.data('program'));
        let sectionsForm = $(container.data('section'));
        let refresh = false;

        programsForm.change(function()
        {
            let programId = programsForm.val();
            programUpdated(programId);
        });

        function programUpdated(programId)
        {
            let sections = programSections[programId] || [];

            if(refresh)
            {
                sectionsForm.select2().empty();
            }
            else
            {
                refresh = true;
            }
            //sectionsForm.find('option').remove().append('<option value="">Geen</option>');

          sectionsForm.select2({
                dropdownParent: sectionsForm.closest('.modal'),
                data: sections,
                placeholder: placeholderName,
                allowClear: true
            });

        }

        programUpdated(programsForm.val());

        let selected = container.data('section-id');
        if(selected)
        {
            sectionsForm.val(selected).trigger('change');
        }
    });

};
