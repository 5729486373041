export function AdminModal() {
    let modal = null;
    let modalEvents = {};

    this.redirect = false;
    this.Url = null;
    this.keyboard = true;

    this.Modal = () => modal;

    this.On = function (event, callback) {
        if (!modalEvents[event]) {
            modalEvents[event] = [];
        }
        modalEvents[event].push(callback);
    };

    this.Off = function (event, callback) {
        if (!modalEvents[event]) {
            return;
        }
        let index = modalEvents[event].indexOf(callback);

        if (index !== -1) {
            modalEvents[event].splice(index, 1);
        }

        if (!callback) {
            modalEvents[event] = [];
        }

    };

    this.fireModalEvent = function (event, response) {
        fireModalEvent(event, response);
    };

    this.OnOnce = function (event, callback) {
        let eventHandler = () => {
            this.Off(event, eventHandler);
            callback.apply(this, arguments);
        };
        this.On(event, eventHandler);
    };

    this.Load = function (url, hideModalOnError, redirect = false, keyboard = true) {

        this.Url = url;
        this.redirect = redirect;
        this.keyboard = keyboard;

        return new Promise((resolve, reject) => {
            createModal(keyboard);
            $.ajax({
                url: url,
                type: 'get',
                success: content => {
                    resolve(content);
                    loadContent(content, this.redirect);
                },
                error: response => {
                    if (hideModalOnError) {
                        setTimeout(() => {
                            modal.modal('hide');
                        });
                    } else {
                        displayModalError(response);
                    }
                    reject(response);
                }
            });

            modal.content.html('');
            modal.modal('show');
        });
    };

    this.Submit = function (formElm) {
        if (formElm.closest('.modal').length === 0) {
            throw 'Form element is not inside a modal.';
        }
        return submit(formElm[0]);
    };

    this.loadContent = function (content) {
        createModal();

        var html = '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';

        modal.content.html(html);
        modal.content.append(content);
        modal.modal('show');
    };

    function loadContent(content, redirect) {
        modal.content.html(content);
        var form = modal.content.find('form');
        form.addClass('modal-form');
        if (!redirect) {
            form.submit(onSubmit)
        }
    }

    // Very simple error modal
    function displayModalError(response) {
        modal.content.html(`
                <div class="modal-body" style="font-size: 2em; color: #c92523; text-align: center;">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <i class="fa fa-exclamation-triangle" style="font-size: 5em;" aria-hidden="true"></i>
                    <div style="margin-bottom: -8px;">` + response.statusText + `</div>
                </div>
            `);
    }

    function onSubmit(ev) {
        let formElm = ev.target;
        let form = $(formElm);

        if (!form.data('redirect')) {
            ev.preventDefault();
            submit(formElm);
        }
    }

    function submit(formElm) {

        let form = $(formElm);
        return new Promise((resolve, reject) => {
            removeErrors(form);

            form.find('input[type="submit"], button, a').addClass('disabled-for-submit disabled').prop('disabled', true);

            $.ajax({
                url: form.attr('action'),
                type: form.attr('method'),
                data: new FormData(formElm),
                dataType: 'JSON',
                processData: false,
                contentType: false,
                success: response => {
                    resolve(response);
                    fireModalEvent('submit', response);
                    modal.modal('hide');
                },
                error: response => {
                    reject(response);
                    form.find('input[type="submit"], button, a').removeClass('disabled-for-submit disabled').prop('disabled', false);
                    displayErrors(form, response.responseJSON);
                    fireModalEvent('error', response.responseJSON);

                }
            });
        });
    }

    function removeErrors(form) {
        form.find('input').removeClass('is-invalid');
        form.find('.invalid-feedback').remove();
        form.find('.form-errors').remove();
        form.find('a.has-error').removeClass('has-error');
    }

    function displayErrors(form, errors) {

        if(!errors){
            return;
        }

        let formErrors = '';
        let tabContent = null;
        if (errors.error) {
            formErrors += '<li>' + errors.error + '</li>\n';
        } else {
            for (let attribute in errors) {
                let inputErrors = '';
                for (let message of errors[attribute]) {
                    if(typeof message === 'object'){
                        for (let item in message){
                            inputErrors += '<li>' + message[item] + '</li>\n';
                        }
                    } else{
                        inputErrors += '<li>' + message + '</li>\n';
                    }
                }


                let input = form.find('#' + attribute);
                if (input.length === 0) {
                    formErrors += inputErrors;
                    continue;
                }

                let errorBlock = $(`
                        <div class="invalid-feedback">
                            <ul class="list-unstyled">
                                ` + inputErrors + `
                            </ul>
                        </div>
                    `);
                input.addClass('is-invalid');

                let inputGroup = input.closest('.input-group');
                if (inputGroup.length > 0) {
                    input = inputGroup;
                }
                input.parent().append(errorBlock);

                let tabId = input.closest('.tab-pane').attr('id');

                if (tabId) {
                    tabContent = $('.nav a[href="#' + tabId + '"]');
                    tabContent.tab('show');
                    tabContent.addClass('has-error');
                }
            }
        }

        if (formErrors.length > 0) {
            let formGroup = form.find('.form-group');
            if (!formGroup.length) {
                formGroup = form.parents().find('.form-horizontal');
            }
            formGroup.first().before($(`
                    <div class="alert alert-danger form-errors">
                        <ul class="list-unstyled">
                            ` + formErrors + `
                        </ul>
                    </div>
                `));

            let tabId = formGroup.closest('.tab-pane').attr('id');

            if (tabId) {
                tabContent = $('.nav a[href="#' + tabId + '"]');
                tabContent.tab('show');
                tabContent.addClass('has-error');
            }
        }

        form.find('.disabled-for-submit').removeClass('disabled-for-submit disabled');
    }

    function createModal(keyboard = true) {
        if (modal) {
            modal.remove();
        }

        modal = $(`
                    <div class="modal fade fade-zoom" tabindex="-1" role="dialog">
                        <div class="modal-flex-container">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content"></div>
                            </div>
                        </div>
                    </div>
                `)
        .modal({
            backdrop: 'static',
            show: false,
            keyboard: keyboard
        });

        $('body').append(modal);

        modal.content = modal.find('.modal-content');

        modal.on('show.bs.modal', function () {
            fireModalEvent('show');

        });
        modal.on('hide.bs.modal', function () {
            fireModalEvent('hide');
        });

        var mobile = function () {
            var check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        };

        if (!mobile()) {
            modal.on('shown.bs.modal', function () {
                $(document.body).addClass('modal-open');
                fireModalEvent('shown');
                modal.content.find('input[type=number],input[type=text],textarea,select').filter(':first').focus();
            });
        }
        modal.on('hidden.bs.modal', function () {
            if ($('.modal.show').length > 0) {
                $(document.body).addClass('modal-open');
            }
            fireModalEvent('hidden', modal);
        });

        return modal;
    }

    function fireModalEvent(event, args) {
        args = Array.prototype.slice.call(arguments, 1);

        if (modalEvents[event]) {
            for (let callback of modalEvents[event]) {
                callback.apply(this, args);
            }
        }
    }
}

/**
 * Default instance
 *
 * @returns {AdminModal}
 */
export default function adminModalInstance() {
    let adminModal = new AdminModal();

    $(document).on('click', '[data-modal]', ev => {
        ev.stopPropagation();

        let elm = $(ev.currentTarget);
        let url = elm.data('modal');

        adminModal.Load(url, false, !!elm.data('redirect'));
    });

    adminModal.On('submit', () => {
        if (window.LaravelDataTables) {
            window.LaravelDataTables.dataTableBuilder.ajax.reload();
        }
    });

    adminModal.On('hidden', (e) => {
        if (window.destroyModal) {
           e.remove()
        }
    });

    return adminModal;
}
