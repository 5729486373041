import Webcam from 'webcam-easy';

class NoMirrorWebcam extends Webcam {
    // override function as facingMode is set to 'user' when webcamList == 1 -> facingMode decides mirroring
    getVideoInputs(mediaDevices) {
        const webcamList = super.getVideoInputs(mediaDevices)
        this._facingMode = 'environment';
        return webcamList;
    }
}

export default NoMirrorWebcam;
